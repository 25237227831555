import React, { useRef } from 'react';
import { Block, Layout, Title, Button } from '@dragonchain-dev/dragon-kit';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { Layout as LayoutPage, SEO } from '../components/_index';

const ContactUs = () => {
  const contactForm = useRef();

  return (
    <LayoutPage>
      <SEO
        title="Contact Us | Dragonchain - Blockchain as a Service"
        description="Is your question not included in our list of Frequently Asked Questions? Feel free to get in touch with us directly by chat, email or by using the contact form."
      />
      <Layout dark background="blue" header="Contact Us" />
      <Layout>
        <Block center>
          <Title>Do you have a sales question or need support?</Title>
          
            Email <a href="mailto:info@Dragonchain.com">info@Dragonchain.com</a> and our team will be in touch.
          
        </Block>
      </Layout>
      <ReactTypeformEmbed
        style={{ zIndex: '-999' }}
        ref={contactForm}
        popup
        url="https://marketing934186.typeform.com/to/j2sLGKM2"
      />
    </LayoutPage>
  );
};

export default ContactUs;
